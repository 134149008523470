<template>
  <b-card>
      <!-- form -->
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-table
            ref="refListTable"
            :fields="fields"
            :items="periodList"
          >
            <template #cell(souceData)="data">
              <v-select
                v-model="data.item.sourceDataId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sourceDataList"
                :reduce="(val) => val.id"
                :clearable="false"
                @input="onToggleSourceData(data.item)"
                label="sourceTitle"
                input-id="sourceDataId"
                placeholder="Select Source Data"/>
            </template>
          </b-table>
        </b-col>
      </b-row>
  </b-card>
</template>

<script>
import ApiService from '@/common/api.service'
// Alert Toast
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'name',
        },
        {
          // A regular column with custom formatter
          key: 'souceData',
          label: 'Souce Data',
          thStyle: { width: '50%' },
        },
      ],
      periodList: [],
      sourceDataList: [],
    }
  },
  methods: {
    fetchPeriods() {
      ApiService.get('PresetAncientPeriod/getall', {}, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.periodList = response.result;
            this.fetchRegionPeriodSourceDatas();
          }
        });
    },

    fetchRegionPeriodSourceDatas() {
      const paramId = this.$router.currentRoute.params.id;
      ApiService.get(`presetregion/getall/periodregions/${paramId}`, {}, this)
        .then(response => {
          if (response.statusCode === 200) {
            const periodRegions = response.result;
            this.periodList.forEach(value => {
              const item = periodRegions.find(x => x.presetAncientPeriodId === value.id);
              if (item) {
                value.sourceDataId = item.sourceDataId;
              }
            });
            this.$refs.refListTable.refresh();
          }
        });
    },

    onToggleSourceData(item) {
      if (this.$router.currentRoute.params.id === '0') {
        AlertService.error(this, 'First Save Region')
        return;
      }
      /* eslint-disable */
      const query = {
        sourceDataId: item.sourceDataId,
        presetAncientPeriodId: item.id,
        presetRegionId: parseInt(this.$router.currentRoute.params.id),
      };

      ApiService.post('PresetRegion/save/period', query, this)
        .then(response => {
          this.showResponse(response);
        });
    },
    showResponse(response) {
      if (response.statusCode === 200) {
        this.$toast({
          component: ToastificationSuccess,
          position: 'top-right',
          props: { text: this.$t(response.message) },
        });
        this.$refs.refListTable.refresh();
      } else {
        AlertService.error(this, this.$t(response.message))
      }
    },

    loadSourceDatas() { 
      this.$store.dispatch('sourceDataManagement/fetchSourceDatasByType', { type: 1 })
        .then(response => {
          this.sourceDataList = response.result;
        });
    },
  },
  created() {
    this.loadSourceDatas();
    this.fetchPeriods();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.source-input {
    box-sizing: border-box;
    margin: 0px;
    outline: 0px;
    padding: 1em;
    height: calc(100% - 3em);
    min-height: 3em;
    width: 100%;
    overflow: auto;
    color: inherit;
    font-size: 0.7em;
    white-space: pre;
}
</style>
