<template>
  <b-tabs
    content-class="col-12 col-md-12 mt-12 mt-md-0"
    pills
    nav-wrapper-class="col-md-12 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('General') }}
          </span>
      </template>

      <preset-region-save />
    </b-tab>
    <!--/ general tab -->

    <!-- general tab -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('Period Source Data') }}
          </span>
      </template>

      <preset-region-period-source-data />
    </b-tab>
    <!--/ general tab -->

  </b-tabs>
</template>

<script>
import PresetRegionSave from './PresetRegionSave.vue'
import PresetRegionPeriodSourceData from './PresetRegionPeriodSourceData.vue'

export default {
  components: {
    PresetRegionSave,
    PresetRegionPeriodSourceData,
  },
}
</script>
