<template>
<div>
  <component :is="'b-card'">
    <!-- Form: General Info Form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-1">
        <b-row>
          <!-- Field: title -->
          <b-col cols="12" md="6" lg="8">
            <b-form-group :label="$t('Title')" label-for="title">
              <validation-provider #default="{ errors }" rules="required" name="Title">
                <b-form-input id="title" v-model="itemData.title" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Source Data -->
          <!-- <b-col cols="12" md="6" lg="4">
            <b-form-group :label="$t('Source Data')" label-for="sourceDataId">
              <validation-provider #default="{ errors }" rules="required" name="Source Data">
                <v-select
                  v-model="itemData.sourceDataId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sourceDataList"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  :state="errors.length > 0 ? false:null"
                  label="sourceTitle"
                  input-id="sourceDataId"
                  placeholder="Select Source Data"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <!-- Field: Region Grup -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="$t('Region Grup')" label-for="presetRegionGrupId">
              <validation-provider #default="{ errors }" rules="required" name="Region Grup">
                <v-select
                  v-model="itemData.presetRegionGrupId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="regionGrupList"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  :state="errors.length > 0 ? false:null"
                  label="grupName"
                  input-id="presetRegionGrupId"
                  placeholder="Select Region Grup"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Active -->
          <b-col cols="12" md="6" lg="6">
            <b-form-group
              label=""
              label-for="contact-options"
              label-class="mb-1">
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-model="itemData.isActive"
                  class="custom-control-primary">
                  {{ $t('Active')}}
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Order Number')" label-for="orderNumber">
              <validation-provider #default="{ errors }" name="orderNumber" rules="required">
                <b-form-input id="orderNumber" v-model="itemData.orderNumber" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Source Data Version')" label-for="sourceCodeVersion">
              <validation-provider #default="{ errors }" name="sourceCodeVersion" rules="required">
                <b-form-input id="sourceCodeVersion" v-model="itemData.sourceCodeVersion" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Latitude')" label-for="originLatitude">
              <validation-provider #default="{ errors }" name="originLatitude">
                <b-form-input id="originLatitude" v-model="itemData.originLatitude" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Longitude')" label-for="originLongitude">
              <validation-provider #default="{ errors }" name="originLongitude">
                <b-form-input id="originLongitude" v-model="itemData.originLongitude" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: desc -->
          <b-col cols="12" md="12" lg="12">
            <label class="mb-1">{{ $t("Description") }}</label>
            <editor
              v-model="itemData.description"
              api-key="'qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'"
              :init="editorInit"
            />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <save-button 
              :onClickSaveAndClose="saveRegion.bind(this, true)"
              :onClickSave="saveRegion.bind(this, false)"
              />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </component>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, min, max,
} from '@validations'

import Editor from '@tinymce/tinymce-vue'

// Alert fade
import { heightFade } from '@core/directives/animations'
// Save Button
import SaveButton from '@/components/SaveButton.vue'

// Alert
import AlertService from '@/common/alert.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  components: {
    Editor,
    SaveButton,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'height-fade': heightFade,
  },

  data() {
    return {
      required,
      min,
      max,

      sourceDataList: [],
      itemData: {
        id: 0,
        title: null,
        description: null,
        sourceDataId: null,
        pcaSourceDataId: null,
        presetRegionGrupId: null,
        isActive: true,
        presetRegionType: 1,
        orderNumber: 0,
        originLatitude: '44.312223',
        originLongitude: '30.046882',
        sourceCodeVersion: null,
      },

      /* eslint-disable */
      editorInit: {
        plugins: [
          'print preview paste importcss searchreplace autolink autosave save directionality code fullscreen image link media template table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap uploadimage textcolor colorpicker',
        ],
        toolbar: 'undo redo | bold italic underline strikethrough | \
          fontselect fontsizeselect formatselect | \
          alignleft aligncenter alignright alignjustify | outdent indent | \
          numlist bullist checklist | \
          forecolor backcolor casechange permanentpen formatpainter removeformat colorpicker | pagebreak | charmap | \
          fullscreen  preview  print | insertfile image media pageembed link anchor | \
          a11ycheck ltr rtl | showcomments addcomment | uploadimage',
        language: 'tr_TR',
        min_height: 200,
        valid_elements: '*[*]',
        extended_valid_elements: 'style,link[href|rel]',
        custom_elements: 'style,link,~link',
        force_br_newlines: true,
        forced_root_block: false,
      },
    }
  },

  methods: {
    fetchItem() {
      const paramId = this.$router.currentRoute.params.id;
      if (parseInt(paramId, 10) !== 0) {
        this.$store.dispatch('presetRegionManagement/fetchPresetRegion', { id: paramId })
          .then(response => {
            if (response.statusCode === 200) {
              this.itemData = response.result;
            } else {
              AlertService.error(this, response.message)
            }
          })
      }
    },

    saveRegion(isClose) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('presetRegionManagement/savePresetRegion', this.itemData)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })
                if (isClose) {
                  this.$router.go(-1)
                } else {
                  this.itemData = response.result;
                  this.$router.push({ name: 'manage-preset-region-save', params: { id: response.result.id } });
                }
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      })
    },

    loadSourceDatas() { 
      this.$store.dispatch('sourceDataManagement/fetchSourceDatasByType', { type: 1 })
        .then(response => {
          this.sourceDataList = response;
        });
    },

    loadRegionGrups() { 
      this.$store.dispatch('presetRegionGrupManagement/fetchPresetRegionGrupList');
    },
  },

  computed: {
    regionGrupList() {
      return this.$store.getters['presetRegionGrupManagement/getPresetRegionGrupList'];
    },
  },

  created() {
    this.loadSourceDatas();
    this.loadRegionGrups();
    this.fetchItem();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
