var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{tag:"component"},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"8"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Title'),"label-for":"title"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false:null},model:{value:(_vm.itemData.title),callback:function ($$v) {_vm.$set(_vm.itemData, "title", $$v)},expression:"itemData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Region Grup'),"label-for":"presetRegionGrupId"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Region Grup"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.regionGrupList,"reduce":function (val) { return val.id; },"clearable":false,"state":errors.length > 0 ? false:null,"label":"grupName","input-id":"presetRegionGrupId","placeholder":"Select Region Grup"},model:{value:(_vm.itemData.presetRegionGrupId),callback:function ($$v) {_vm.$set(_vm.itemData, "presetRegionGrupId", $$v)},expression:"itemData.presetRegionGrupId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"","label-for":"contact-options","label-class":"mb-1"}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-checkbox',{staticClass:"custom-control-primary",model:{value:(_vm.itemData.isActive),callback:function ($$v) {_vm.$set(_vm.itemData, "isActive", $$v)},expression:"itemData.isActive"}},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")])],1)])],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Order Number'),"label-for":"orderNumber"}},[_c('validation-provider',{attrs:{"name":"orderNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"orderNumber","state":errors.length > 0 ? false:null},model:{value:(_vm.itemData.orderNumber),callback:function ($$v) {_vm.$set(_vm.itemData, "orderNumber", $$v)},expression:"itemData.orderNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Source Data Version'),"label-for":"sourceCodeVersion"}},[_c('validation-provider',{attrs:{"name":"sourceCodeVersion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sourceCodeVersion","state":errors.length > 0 ? false:null},model:{value:(_vm.itemData.sourceCodeVersion),callback:function ($$v) {_vm.$set(_vm.itemData, "sourceCodeVersion", $$v)},expression:"itemData.sourceCodeVersion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Latitude'),"label-for":"originLatitude"}},[_c('validation-provider',{attrs:{"name":"originLatitude"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"originLatitude","state":errors.length > 0 ? false:null},model:{value:(_vm.itemData.originLatitude),callback:function ($$v) {_vm.$set(_vm.itemData, "originLatitude", $$v)},expression:"itemData.originLatitude"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Longitude'),"label-for":"originLongitude"}},[_c('validation-provider',{attrs:{"name":"originLongitude"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"originLongitude","state":errors.length > 0 ? false:null},model:{value:(_vm.itemData.originLongitude),callback:function ($$v) {_vm.$set(_vm.itemData, "originLongitude", $$v)},expression:"itemData.originLongitude"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Description")))]),_c('editor',{attrs:{"api-key":"'qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'","init":_vm.editorInit},model:{value:(_vm.itemData.description),callback:function ($$v) {_vm.$set(_vm.itemData, "description", $$v)},expression:"itemData.description"}})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('save-button',{attrs:{"onClickSaveAndClose":_vm.saveRegion.bind(this, true),"onClickSave":_vm.saveRegion.bind(this, false)}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }